import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Footer.module.scss';

const encryptedMail = btoa("info@cgs-online.de");

class Footer extends React.Component {

  mailTo() {
    window.location.href = "mailto:" + atob(encryptedMail);
  }

  render() {
    const { t } = this.props;
    return (
      <footer className={styles.Footer} data-testid="Footer">
        <Container className="my-5">
          <Row>
            <Col xs={6} md={6} lg={6}>
              <strong className="d-block" mb={2}>CGS mbH - Consulting Gesellschaft für Systementwicklung</strong>
              <ul className="list-unstyled mb-0">
                <li>Lange Straße 1, 38100 Braunschweig</li>
                <li><input type="button" className={styles.jsLink} value="info(at)cgs-online.de" onClick={this.mailTo}></input></li>
                <li><a href="tel:+495311209500">+49 (0) 531 / 12 09 - 500</a></li>
                <li>+49 (0) 531 / 12 09 - 599</li>
              </ul>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Row className="h-50">
                <Col>
                  <div className="float-md-right mt-3 mt-md-0">
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

                    <a
                        href="https://www.facebook.com/CGS.mbH/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fa fa-facebook-official"
                        style={{ color: 'blue', fontSize: '3em', marginRight: '20px' }}
                    ></a>

                    <a
                        href="https://www.linkedin.com/products/consulting-gesellschaft-f%C3%BCr-systementwicklung-cgs-mbh-rias/?trk=organization_guest_product_card_related-content-card"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fa fa-linkedin-square"
                        style={{ color: 'blue', fontSize: '3em', marginRight: '20px' }}
                    ></a>
                  </div>
                </Col>
              </Row>
              <Row className="h-50 align-items-end">
                <Col className="col-12">
                  <div className="float-md-right mt-3 mt-md-0">
                    <a href="https://www.cgs-online.de/impressum/" target="_blank" rel="noopener noreferrer">{t("impressum")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/agb/" target="_blank" rel="noopener noreferrer">{t("agb")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/datenschutz/" target="_blank" rel="noopener noreferrer">{t("privacystatement")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/kontakt/" target="_blank" rel="noopener noreferrer">{t("contact")}</a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className={styles.Copyright}>
          <Container>
            <Row>
              <Col>
                <p className="text-right text-light my-2"><small>Copyright © 2024  Alle Rechte vorbehalten</small></p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default withTranslation()(Footer);
