import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import Footer from './components/Footer/Footer';
import NavHeader from './components/NavHeader/NavHeader';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      refs: []
    };
    this.setRefs = this.setRefs.bind(this);
  }

  setRefs(refs) {
    this.setState({
      refs: refs
    });
  }

  render() {
    return (
      <HashRouter className="App">
        <NavHeader refs={this.state.refs}></NavHeader>
        <header className="App-header"/>
        <div data-sticky-container>
          <Switch>
            <Route path="/contact">
              <Contact refCallback={this.setRefs}/>
            </Route>
            <Route path="/">
              <Home refCallback={this.setRefs}/>
            </Route>
          </Switch>
        </div>
        <Footer></Footer>
      </HashRouter>
    );
  }
}

export default App;
