import React from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.scss';
import { withTranslation } from 'react-i18next';
import PageContent from '../../components/PageContent/PageContent';
import { Col, Image, Row } from 'react-bootstrap';
import iconpruefungscockpit from '../../assets/rias_iconset/icon-pruefungscockpit.png';
import iconrisikomanagement from '../../assets/rias_iconset/icon-risikomanagement.png'
import iconpruefungsprogramm from '../../assets/rias_iconset/icon-pruefungsprogramm2.png';
import iconpruefungsverwaltung from '../../assets/rias_iconset/icon-pruefungsverwaltung.png';
import iconfollowup1 from '../../assets/rias_iconset/icon-followup1.png';
import iconreporting from '../../assets/rias_iconset/icon-reporting1.png';
import iconwissensdatenbank from '../../assets/rias_iconset/icon-wissensdatenbank.png';
import iconadministration from '../../assets/rias_iconset/icon-administration2.png';
import portraitHuchzermeyer from '../../assets/portraits/huchzermeyer.png';
import portraitFranz from '../../assets/portraits/franz.png';



const iconCol = 2;
const textCol = 4;

const encryptedMailHuchzermeyer = btoa("fhuchzermeyer@cgs-online.de")
const encryptedMailFranz = btoa("sfranz@cgs-online.de")


class Home extends React.Component {
  constructor(props) {
    super();
    this.state = {
      refs: [
        {name: "home:overview.navlink",ref:React.createRef()},
        {name: "home:pros.navlink",ref:React.createRef()},
        {name: "home:features.navlink",ref:React.createRef()}
      ]
    }
    if(props.refCallback) {
      props.refCallback(this.state.refs);
    }
  }

  mailTo(encryptedMail) {
    window.location.href = "mailto:" + atob(encryptedMail);
  }

  renderParagraphs(t, index) {
    var paragraph = t("home:overview.paragraph." + index);
    if(paragraph !== ("overview.paragraph." + index)) {
      if(index === 0) {
        return <div>{paragraph}{this.renderParagraphs(t, index+1)}</div>
      } else {
        return <span><br/><br/>{paragraph}{this.renderParagraphs(t, index+1)}</span>
      }
    }
  }

  render() {
    const { t } = this.props;

    const prosMap = [
      {hl:t("home:pros.list.headlines.0"),content:t("home:pros.list.paragraphs.0")},{hl:t("home:pros.list.headlines.1"),content:t("home:pros.list.paragraphs.1")},{hl:t("home:pros.list.headlines.2"),content:t("home:pros.list.paragraphs.2")},
      {hl:t("home:pros.list.headlines.3"),content:t("home:pros.list.paragraphs.3")},{hl:t("home:pros.list.headlines.4"),content:t("home:pros.list.paragraphs.4")},{hl:t("home:pros.list.headlines.5"),content:t("home:pros.list.paragraphs.5")},
      {hl:t("home:pros.list.headlines.6"),content:t("home:pros.list.paragraphs.6")}
    ]
    
    const featuresMap = [
      {col1:{icon:iconpruefungscockpit,hl:t("home:features.list.headlines.0"),content:t("home:features.list.paragraphs.0")},col2:{icon:iconrisikomanagement,hl:t("home:features.list.headlines.1"),content:t("home:features.list.paragraphs.1")}},
      {col1:{icon:iconpruefungsprogramm,hl:t("home:features.list.headlines.2"),content:t("home:features.list.paragraphs.2")},col2:{icon:iconpruefungsverwaltung,hl:t("home:features.list.headlines.3"),content:t("home:features.list.paragraphs.3")}},
      {col1:{icon:iconfollowup1,hl:t("home:features.list.headlines.4"),content:t("home:features.list.paragraphs.4")},col2:{icon:iconreporting,hl:t("home:features.list.headlines.5"),content:t("home:features.list.paragraphs.5")}},
      {col1:{icon:iconwissensdatenbank,hl:t("home:features.list.headlines.6"),content:t("home:features.list.paragraphs.6")},col2:{icon:iconadministration,hl:t("home:features.list.headlines.7"),content:t("home:features.list.paragraphs.7")}}
    ]

    var overview = this.renderParagraphs(t, 0);

    var pros = 
      <div>
        <h4>{t("home:pros.headline")}</h4><br/>
        {prosMap.map((pro,idx) => {
          if(idx < prosMap.length - 1) {
            return (
              <div key={"pro"+idx}>
                <h6><strong>{pro.hl}</strong></h6>
                {pro.content}<br/><br/><br/>
              </div>
            );
          } else {
            return (
              <div key={"pro"+idx}>
                <h6><strong>{pro.hl}</strong></h6>
                {pro.content}
              </div>
            );
          }
        })}
      </div>

    var features = 
      <div>
        <h4>{t("home:features.headline")}</h4>
        {featuresMap.map((feature, idx) => {
          return (
            <Row key={"feature"+idx} className="align-items-center" >
              <Col className="my-4" xs={iconCol * 2} lg={iconCol}>
                <Image src={feature.col1.icon} fluid className={styles.Icon}/>
              </Col>
              <Col className="my-4" xs={textCol * 2} lg={textCol}>
                <h6><strong>{feature.col1.hl}</strong></h6>
                {feature.col1.content}
              </Col>
              <Col className="my-4" xs={iconCol * 2} lg={iconCol}>
                <Image src={feature.col2.icon} fluid className={styles.Icon}/>
              </Col>
              <Col className="my-4" xs={textCol * 2} lg={textCol}>
                <h6><strong>{feature.col2.hl}</strong></h6>
                {feature.col2.content}
              </Col>
            </Row>
          );
        })}
      </div>

    var getToKnow = 
    <div>
      <h4>{t("home:team.headline")}</h4>
      <Row className="mt-5">
        <Col xs={6} lg={2}>
          <Image className={styles.Center} src={portraitHuchzermeyer} fluid/>
        </Col>
        <Col xs={6} lg={4}>
          <strong>Felix Huchzermeyer</strong><br/>
          {t("home:team.huchzermeyer.description")}<br/><br/>
          <input type="button" className="jsLink" value="fhuchzermeyer(at)cgs-online.de" onClick={() => this.mailTo(encryptedMailHuchzermeyer)}></input>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={6} lg={2}>
          <Image className={styles.Center} src={portraitFranz} fluid/>
        </Col>
        <Col xs={6} lg={4}>
          <strong>Stefan Franz</strong><br/>
          {t("home:team.franz.description")}<br/><br/>
          <a href="tel:+49 531 1209 384">+49 531 1209 384</a><br/>
          <input type="button" className="jsLink" value="sfranz(at)cgs-online.de" onClick={() => this.mailTo(encryptedMailFranz)}></input>
        </Col>
      </Row>
    </div>

    return(
      <div className={styles.Home} data-testid="Home">
        <PageContent contentRef={this.state.refs[0].ref} headline={t("home:overview.headline")} subHeadline={t("home:overview.subheadline")} squareDots={true} content={overview}></PageContent>
        <PageContent contentRef={this.state.refs[1].ref} squareDots={false} content={pros}></PageContent>
        <PageContent contentRef={this.state.refs[2].ref} content={features}></PageContent>
        <PageContent content={getToKnow}></PageContent>
    </div>
    );
  }
}

Home.propTypes = {
  refCallback: PropTypes.func
};

Home.defaultProps = {
  refCallback: null
};

export default withTranslation()(Home);
