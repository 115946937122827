import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './NavHeader.module.scss';
import logo from '../../assets/logoRias.png';
import { Button, Dropdown, DropdownButton, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NavHeader extends React.Component {

  constructor(props) {
    super();
    this.state = {locale: "de"};
  }

  componentDidMount() {
    const detectBrowserLanguage = require('detect-browser-language')
    var userLanguage = detectBrowserLanguage();
    if(!userLanguage.startsWith("de")) {
      const { i18n } = this.props;
      this.setState({ locale: "en" });
      i18n.changeLanguage("en");
    }
    window.onscroll = this.onScroll;
  }

  // change Header-Size depending on scrollTop
  onScroll() {
    var maxPx = 120;
    var px = 70;
    if(document.body.scrollTop < 200 && document.documentElement.scrollTop < 200) {
      if (document.body.scrollTop > 0) {
        px = maxPx - ((document.body.scrollTop) / 4);
      } else if (document.documentElement.scrollTop > 0) {
        px = maxPx - ((document.documentElement.scrollTop) / 4);
      } else {
        px = maxPx;
      }
    }
    document.getElementById("headerbrand").style.height = px + "px";
  }

  changeLanguage(i18n) {
    if(this.state.locale === "de") {
      i18n.changeLanguage("en");
      this.setState({locale: "en"});
    } else {
      i18n.changeLanguage("de");
      this.setState({locale: "de"});
    }
  }

  getLanguageToChange() {
    if(this.state.locale === "de") {
      return "languages.en";
    } else {
      return "languages.de";
    }
  }
  
  render() {
    const { t, i18n } = this.props;
    return(
      <Navbar data-testid="NavHeader" bg="white opacity" expand="md" fixed="top">
        <Navbar.Brand href="#home" className={styles.HeaderBrand}><img id="headerbrand" src={logo} className={styles.HeaderLogo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-100">
            {this.props.refs.map(ref => {
              return <Nav.Link key={ref.name} onClick={() => window.scrollTo(0, ref.ref.current.offsetTop-85)}>{t(ref.name)}</Nav.Link>;
            })} 
            <div className={styles.Divider}>|</div>
            <Link to="/" className="nav-link" onClick={() => window.scrollTo(0,0)}>{t("homeButton")}</Link>
          </Nav>
          <Button className={styles.HeaderButton} variant="outline-success" size="sm" onClick={() => {window.location.href = "#/contact"; window.scrollTo(0,this.props.refs[0].ref.current.offsetTop-85)}}>{t("demobutton")}</Button>
          <DropdownButton className={styles.HeaderButton} variant="outline-secondary" menuAlign="right" size="sm" id="dropdown-language" title={t("languages." + this.state.locale)}>
            <Dropdown.Item onClick={() => this.changeLanguage(i18n)}>{t(this.getLanguageToChange())}</Dropdown.Item>
          </DropdownButton>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

NavHeader.propTypes = {
  refs: PropTypes.array
};

NavHeader.defaultProps = {
  refs: []
};

export default withTranslation()(NavHeader);
