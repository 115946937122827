import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './Contact.module.scss';
import PageContent from '../../components/PageContent/PageContent';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';

const formFields = ["formCompany", "formSalutation", "formSurname", "formEmail", "formMessage", "formDataPrivacy"];
const formOptionalFields = ["formName", "formAddress", "formPlz", "formCity", "formPhone"];
const url = "/contact/send.php";
const contentRef = React.createRef();

class Contact extends React.Component {
  constructor(props) {
    super();
    var stateBuild = {
      refs: [],
      disableSubmit: true,
      sendSuccess: false,
      sendError: ""
    }
    formFields.forEach(field => {
      if(field === "formDataPrivacy") {
        stateBuild[field] = {
          value: false,
          valid: true
        }
      } else {
        stateBuild[field] = {
          value: "",
          valid: true
        }
      }
    });
    formOptionalFields.forEach(field => {
      stateBuild[field] = {
        value: ""
      }
    });
    this.state = stateBuild;
    if(props.refCallback) {
      props.refCallback(this.state.refs);
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOptionalInputChange = this.handleOptionalInputChange.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate () {
    
  }
  
  handleInputChange(event) {
    var key = event.target.id;
    var value;
    if(key === "formDataPrivacy") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    var valid = this.validateNotEmpty(value);
    var obj = {};
    obj[key] = {
      value: value,
      valid: valid
    };
    this.setState(obj);
    if(key !== "formEmail" || this.validateEmail(value)) {
      this.checkEnableSubmit(obj);
    } else {
      this.setState({disableSubmit:true});
    }
  }

  handleOptionalInputChange(event) {
    var key = event.target.id;
    var value = event.target.value;
    var obj = {};
    obj[key] = {
      value: value
    };
    this.setState(obj);
  }

  handleEmailBlur(event) {
    var key = event.target.id;
    var value = event.target.value;
    var valid = this.validateEmail(value);
    var obj = {};
    obj[key] = {
      value: value,
      valid: valid
    };
    this.setState(obj);
    this.checkEnableSubmit(obj);
  }

  validateNotEmpty(value) {
    if (!value || value === "") {
      return false;
    } else {
      return true;
    }
  }

  validateEmail(value) {
    if(this.validateNotEmpty(value)) {
      const regex = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
      return value.match(regex);
    }
  }

  checkEnableSubmit(newValue) {
    var disableSubmit = false;
    formFields.forEach(field => {
      var inputField = newValue[field];
      if(!inputField) {
        inputField = this.state[field];
      }
      if(!inputField.value || inputField.value === "" || !inputField.valid) {
        disableSubmit = true
        return;
      }
    });
    this.setState({disableSubmit:disableSubmit});
    return !disableSubmit;
  }

  handleSubmit() {
    if(this.checkEnableSubmit({})) {
      var thisVar = this;
      const data = {};
      formFields.forEach(field => {
        data[field] = this.state[field].value;
      });
      formOptionalFields.forEach(field => {
        data[field] = this.state[field].value;
      });
      axios
      .post(url, data)
      .then(data => {
        thisVar.setState({
          sendSuccess: true,
          sendError: ""
        });
        window.scrollTo(0, contentRef.current.offsetTop-85);
        setTimeout(() => window.location.href = "/", 5000);
      })
      .catch(error => {
        var errorMsg;
        if (error.response) {
          // Request made and server responded
          errorMsg = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          errorMsg = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMsg = error.message;
        }
        thisVar.setState({
          sendSuccess: false,
          sendError: errorMsg
        });
        window.scrollTo(0, contentRef.current.offsetTop-85);
      });
    }
  }

  render() {
    const { t } = this.props;

    var datenschutzLabel = 
      <div>
        {t("contact:privacy.0")}
        <a href="https://www.cgs-online.de/datenschutz/" target="_blank" rel="noopener noreferrer">{t("contact:privacy.1")}</a>
        {t("contact:privacy.2")}
      </div>;
    var contact = 
    <Form noValidate>
      <Alert key="sendSuccess" variant="success" show={this.state.sendSuccess}>
        <Alert.Heading>{t("contact:requestSend.sent.0")}</Alert.Heading>
        {t("contact:requestSend.sent.1")}
      </Alert>
      <Alert key="sendError" variant="danger" show={this.state.sendError !== ""}>
        <Alert.Heading>{t("contact:requestSend.error.1")}</Alert.Heading>
        {this.state.sendError.split("\n").map((errorMsg, idx) => {
          return <p id={"error" + idx}>{errorMsg}</p>;
        })}
      </Alert>
      <Form.Row>
        <Form.Group as={Col} xs={6} md={4} controlId="formCompany">
          <Form.Label>{t("contact:company.name")}</Form.Label>
          <Form.Control required type="company" placeholder={t("contact:company.name")} onChange={this.handleInputChange} isInvalid={!this.state.formCompany.valid}/>
          <Form.Control.Feedback type="invalid">
          {t("contact:company.invalid")}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Group as={Row} controlId="formSalutation">
        <Col xs={2} lg={1}><Form.Check required name="formSalutation" type="radio" value={t("contact:salutation.female")} label={t("contact:salutation.female")} onChange={this.handleInputChange} isInvalid={!this.state.formSalutation.valid}/></Col>
        <Col xs={2} lg={1}><Form.Check required name="formSalutation" type="radio" value={t("contact:salutation.male")} label={t("contact:salutation.male")} onChange={this.handleInputChange} isInvalid={!this.state.formSalutation.valid}/></Col>
        <Form.Control.Feedback type="invalid">
          {t("contact:company.invalid")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} xs={6} md={4} controlId="formName">
          <Form.Label>{t("contact:name.prename")} {t("contact:optional")}</Form.Label>
          <Form.Control type="name" placeholder={t("contact:name.prename")} onChange={this.handleOptionalInputChange}/>
        </Form.Group>
        <Form.Group as={Col} xs={6} md={4} controlId="formSurname">
          <Form.Label>{t("contact:name.surname")}</Form.Label>
          <Form.Control required type="surname" placeholder={t("contact:name.surname")} onChange={this.handleInputChange} isInvalid={!this.state.formSurname.valid}/>
          <Form.Control.Feedback type="invalid">
            {t("contact:name.invalid")}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} md={8} controlId="formAddress">
          <Form.Label>{t("contact:address")} {t("contact:optional")}</Form.Label>
          <Form.Control type="address" placeholder={t("contact:address")} onChange={this.handleOptionalInputChange}/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} md={4} controlId="formPlz">
          <Form.Label>{t("contact:postcode")} {t("contact:optional")}</Form.Label>
          <Form.Control type="plz" placeholder={t("contact:postcode")} onChange={this.handleOptionalInputChange}/>
        </Form.Group>
        <Form.Group as={Col} xs={6} md={4} controlId="formCity">
          <Form.Label>{t("contact:city")} {t("contact:optional")}</Form.Label>
          <Form.Control type="city" placeholder={t("contact:city")} onChange={this.handleOptionalInputChange}/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} md={4} controlId="formPhone">
          <Form.Label>{t("contact:phone")} {t("contact:optional")}</Form.Label>
          <Form.Control type="phone" placeholder={t("contact:phone")} onChange={this.handleOptionalInputChange}/>
        </Form.Group>
        <Form.Group as={Col} xs={6} md={4} controlId="formEmail">
          <Form.Label>{t("contact:email.value")}</Form.Label>
          <Form.Control required type="email" placeholder={t("contact:email.value")} onChange={this.handleInputChange} onBlur={this.handleEmailBlur} isInvalid={!this.state.formEmail.valid}/>
          <Form.Control.Feedback type="invalid">
            {t("contact:email.invalid")}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} md={8} controlId="formMessage">
          <Form.Label>{t("contact:message.value")}</Form.Label>
          <Form.Control required as="textarea" rows={5} onChange={this.handleInputChange} isInvalid={!this.state.formMessage.valid}/>
          <Form.Control.Feedback type="invalid">
            {t("contact:message.invalid")}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Group as={Row} controlId="formDataPrivacy">
        <Col xs={12} md={8}><Form.Check required type="checkbox" label={datenschutzLabel} onChange={this.handleInputChange} isInvalid={!this.state.formDataPrivacy.valid}/></Col>
      </Form.Group>
      <Button variant="secondary" type="button" disabled={this.state.disableSubmit} onClick={this.handleSubmit}>
        {t("contact:requestSend.send")}
      </Button>
    </Form>;

    return(
      <div className={styles.Contact} data-testid="Contact">
        <PageContent headline={t("contact:headline")} content={contact} styleBackward={false} contentRef={contentRef} ></PageContent>
      </div>
    );
  }
}

Contact.propTypes = {
  refCallback: PropTypes.func
};

Contact.defaultProps = {
  refCallback: null
};

export default withTranslation()(Contact);
