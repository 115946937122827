import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonDE from './translations/de/common.json';
import homeDE from './translations/de/home.json';
import contactDE from './translations/de/contact.json';
import commonEN from './translations/en/common.json';
import homeEN from './translations/en/home.json';
import contactEN from './translations/en/contact.json';

const resources = {
    de: {
        common: commonDE,
        home: homeDE,
        contact: contactDE
    },
    en: {
        common: commonEN,
        home: homeEN,
        contact: contactEN
    }
};

i18n.use(initReactI18next).init({
    resources,
    defaultNS: "common",
    lng: "de",
    interpolation: {
        escapeValue: false
    }
});

i18n.changeLanguage();

export default i18n;