import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageContent.module.scss';
import { Col, Container, Row } from 'react-bootstrap';

class PageContent extends React.Component {
  render() {
    var headline = null;
    var subheadline = null;
    var squaredots = null;
    var contentclass = "content relative";
    var pageContentStyle;
    if(this.props.styleBackward) {
      pageContentStyle = styles.PageContentBackward;
    } else {
      pageContentStyle = styles.PageContent;
    }
    if(this.props.subHeadline && this.props.subHeadline.length !== 0) {
      subheadline = <strong>{this.props.subHeadline}</strong>;
      contentclass = "content mt-5 relative";
      pageContentStyle = pageContentStyle + " " + styles.NoPaddingTop;
    }
    if(this.props.headline && this.props.headline.length !== 0) {
      headline = 
        <Row className="no-gutters mb-5 mt-4 mx--50">
          <Col>
            <h1>
              {this.props.headline}<br />
              {subheadline}
            </h1>
          </Col>
        </Row>;
      contentclass = "content mt-5 relative";
      pageContentStyle = pageContentStyle + " " + styles.NoPaddingTop;
    }
    if(this.props.squareDots) {
      //squaredots = <div className={styles.SquareDots + " " + styles.SquareDotsLeft}></div>;
    }

    return(
      <div className={pageContentStyle} data-testid="PageContent" ref={this.props.contentRef}>
        <Container>
          {headline}
          <Row className={contentclass}>
            <Col xs={12}>
              {squaredots}
              <div className={styles.Content}>
                {this.props.content}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

PageContent.propTypes = {
  contentRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func, 
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  squareDots: PropTypes.bool,
  content: PropTypes.node,
  styleBackward: PropTypes.bool
};

PageContent.defaultProps = {
  contentRef: React.createRef(),
  headline: null,
  subHeadline: null,
  squareDots: false,
  content: <div></div>,
  styleBackward: false
};

export default PageContent;
